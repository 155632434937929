export function sendCriteoEventCriteo(eventParams) {
  const deviceType = /iPad/.test(navigator.userAgent)
    ? 't'
    : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
        navigator.userAgent,
      )
    ? 'm'
    : 'd'
  const misCookies = document.cookie.split(';')
  const email = misCookies.find(e => e.includes('email'))

  window.criteo_q.push(
    { event: 'setAccount', account: 67539 },
    { event: 'setEmail', email: email ? email.replace('email=', '') : 'none' },
    { event: 'setSiteType', type: deviceType },
    ...eventParams,
  )
}
